import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../Pages/Home";
import Next from "../Pages/Next";
import PaymentStatus from "../Pages/PaymentStatus";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AllRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/home" element={<Home />} />
        <Route path="/next" element={<Next />} />
        <Route path="/paymentstatus" element={<PaymentStatus />} />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default AllRoutes;
