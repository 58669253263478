import React from 'react'
import CryptoJS from 'crypto-js';
const algorithm = 'sha256';

export default function hashingData(data, id) {

  let reqkey = '';
  if(id == 1){

    reqkey = process.env.REACT_APP_HASH_1;

  }else if(id == 2){

    reqkey = process.env.REACT_APP_HASH_2;

  }else if(id == 3){

    reqkey = process.env.REACT_APP_HASH_3;

  }else if(id == 4){

    reqkey = process.env.REACT_APP_HASH_4;

  }else if(id == 5){

    reqkey = process.env.REACT_APP_HASH_5;

  }else if(id == 6){

    reqkey = process.env.REACT_APP_HASH_6;

  }else if(id == 7){
    
    reqkey = process.env.REACT_APP_HASH_7;

  }else if(id == 8){

    reqkey = process.env.REACT_APP_HASH_8;

  }else if(id == 9){

    reqkey = process.env.REACT_APP_HASH_9;

  }else if(id == 10){

    reqkey = process.env.REACT_APP_HASH_10;

  }else if(id == 11){

    reqkey = process.env.REACT_APP_HASH_11;

  }else if(id == 12){

    reqkey = process.env.REACT_APP_HASH_12;

  }else if(id == 13){

    reqkey = process.env.REACT_APP_HASH_13;

  }else if(id == 14){

    reqkey = process.env.REACT_APP_HASH_14;

  }else if(id == 15){

    reqkey = process.env.REACT_APP_HASH_15

  }else if(id == 16){

    reqkey = process.env.REACT_APP_HASH_16;

  }else if(id == 17){

    reqkey = process.env.REACT_APP_HASH_17;

  }else if(id == 18){

    reqkey = process.env.REACT_APP_HASH_18;

  }else if(id == 19){

    reqkey = process.env.REACT_APP_HASH_19;

  }else if(id == 20){

    reqkey = process.env.REACT_APP_HASH_20;

  }

  if((reqkey == "" || reqkey == undefined || reqkey == null)){
    reqkey = process.env.REACT_APP_HASH_4;
  }
  const algorithm = CryptoJS.algo.SHA256;
  const finaldata = JSON.stringify(data) + reqkey;
  const hash = algorithm.create().update(finaldata).finalize().toString();
  // console.log("hash here", hash, id)
  return { reqdata: hash };
}

