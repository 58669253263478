import React, { createContext, useState } from "react";

// Create the context
export const AppContext = createContext();

// Create a provider component
export const AppContextProvider = ({ children }) => {
  const apiendpointsStage = {
    createOrder: "/createnewOrderDirect",
    proAccessToken: "/pro-access-token",
    getOrderDetails: "/getOrderDetails",
    updateTransactionStatus: "/updateTransactionStatus",
  };
  const apiendpointsProd = {
    createOrder: "/create-new-order",
    proAccessToken: "/direct-sdk/pro-access-token",
    getOrderDetails: "/direct-sdk/get-order-details",
    updateTransactionStatus: "/direct-sdk/update-transaction-status",
  };
  const [optionData, setOptionData] = useState("");

  return <AppContext.Provider value={{ apiendpoints: apiendpointsProd, setOptionData, optionData }}>{children}</AppContext.Provider>;
};
