import React, { useContext, useEffect } from "react";
import { useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import "../CSS/sdk.css";
import "react-phone-input-2/lib/style.css";

import { AppContext } from "../context/AppContext";

import encryptedDataF from "../Components/folderx/encryption";
import hashingData from "../Components/folderx/hashing";
import axiosInstance from "../Components/folderx/InterceptorAxios";


function Next() {
  const [modalIsOpen, setIsOpen] = useState(true);
  const [show, setShow] = useState("");

  const [getOrderId, setgetOrderId] = useState("");
  const [url, setUrl] = useState("");

  const [error, setError] = useState(false);

  const { optionData, apiendpoints } = useContext(AppContext);

  console.log("nexttttttttttt", optionData, apiendpoints);
  const run = async (e) => {
    const supportedCurrencies = ["USD", "usd", "AED", "aed"];
    const orderCurrency = optionData.orderCurrency;
    if (!supportedCurrencies.includes(orderCurrency)) {
      toast.warning("Currency not supported!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setError(true);
      return;
    }
    let networkRequestData = [
      {
        email: optionData.email,
        pin: "111111",
        ...optionData,
      },
    ];

    const { encryptedData, id } = encryptedDataF(networkRequestData);
    const { reqdata } = hashingData(networkRequestData, id);
    if (
      encryptedData == "" ||
      encryptedData == null ||
      encryptedData == undefined ||
      id == undefined ||
      id == null ||
      isNaN(id) ||
      id == "" ||
      reqdata == "" ||
      reqdata == null ||
      reqdata == undefined
    ) {
      toast.error("Sorry! we could not process this request at this moment. Please try again later.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      const myData = {
        data: encryptedData,
        reqid: id,
        reqdata: reqdata,
      };

      axiosInstance
        .post(apiendpoints?.createOrder, myData)
        .then((responsnse) => {
          console.log({ responsnse });
          if (responsnse.status == 201) {
            toast.warning("Something went wrong", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          } else {
            setgetOrderId(responsnse?.data?.reference);
            localStorage.setItem("token", responsnse?.data?.accessToken);
            localStorage.setItem("reference", responsnse?.data?.reference);
            setShow("paymentoption");
            setUrl(responsnse?.data.href);
          }
        })
        .catch((err) => {
          console.log("err: ", err);
          toast.warning("Something went wrong", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
      // }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      run();
    }, 1000);
  }, []);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "20px",
      backgroundColor: "#6CF",
      height: "100%",
      padding: 0,
      overflow: "hidden",
    },
    overlay: {
      overflowY: "hidden",
    },
  };

  return (
    <Modal isOpen={modalIsOpen} style={customStyles} contentLabel="" ariaHideApp={false}>
      {show === "" ? (
        !error ? (
          <div
            style={{
              backgroundColor: "#ffffff",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <img src={"https://epay-imagess.s3.ap-northeast-2.amazonaws.com/gifFile.GIF"} style={{ width: "360px", height: "200px" }} alt="Home GIF" />
          </div>
        ) : (
          <div className="mainf p-2 h-100 w-100">
            <div className="card">
              <img src="https://epay-imagess.s3.ap-northeast-2.amazonaws.com/failed.png" height="150px" width="200px" alt="Failed" />
              <h1 className="fail">Currency Error</h1>
              <p>Currency not supported, please contact your administrator!</p>
            </div>
          </div>
        )
      ) : null}
      {show == "paymentoption" && (
        <div className="secondDiv">
          <iframe title="ePay" width="100%" height="800" src={url} frameBorder="0" />
        </div>
      )}
    </Modal>
  );
}

export default Next;
