
import CryptoJS from 'crypto-js'; 
let min = 1;
let max = 20;
const id = Math.floor(Math.random() * (max - min + 1)) + min;
const iv = CryptoJS.enc.Base64.parse('eU6D3jhq3zFZuvpmMhBbMQ==');

const encryptedDataF = (data) => {
  let mazimatickey = '';
  if(id == 1){

    mazimatickey = process.env.REACT_APP_Encyption_1;

  }else if(id == 2){

    mazimatickey = process.env.REACT_APP_Encyption_2;

  }else if(id == 3){

    mazimatickey = process.env.REACT_APP_Encyption_3;

  }else if(id == 4){

    mazimatickey = process.env.REACT_APP_Encyption_4;

  }else if(id == 5){

    mazimatickey = process.env.REACT_APP_Encyption_5;

  }else if(id == 6){

    mazimatickey = process.env.REACT_APP_Encyption_6;

  }else if(id == 7){
    
    mazimatickey = process.env.REACT_APP_Encyption_7;

  }else if(id == 8){

    mazimatickey = process.env.REACT_APP_Encyption_8;

  }else if(id == 9){

    mazimatickey = process.env.REACT_APP_Encyption_9;

  }else if(id == 10){

    mazimatickey = process.env.REACT_APP_Encyption_10;

  }else if(id == 11){

    mazimatickey = process.env.REACT_APP_Encyption_11;

  }else if(id == 12){

    mazimatickey = process.env.REACT_APP_Encyption_12;

  }else if(id == 13){

    mazimatickey = process.env.REACT_APP_Encyption_13;

  }else if(id == 14){

    mazimatickey = process.env.REACT_APP_Encyption_14;

  }else if(id == 15){

    mazimatickey = process.env.REACT_APP_Encyption_15

  }else if(id == 16){

    mazimatickey = process.env.REACT_APP_Encyption_16;

  }else if(id == 17){

    mazimatickey = process.env.REACT_APP_Encyption_17;

  }else if(id == 18){

    mazimatickey = process.env.REACT_APP_Encyption_18;

  }else if(id == 19){

    mazimatickey = process.env.REACT_APP_Encyption_19;

  }else if(id == 20){

    mazimatickey = process.env.REACT_APP_Encyption_20;

  }
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    mazimatickey,
    {
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }
  );
  return { encryptedData: encrypted.toString(), id: id};
};

export default encryptedDataF;
