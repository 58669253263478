import React, { useContext } from "react";
import { AppContext } from "./context/AppContext"; // Import AppContext instead of AppContextProvider
import AllRoutes from "./Routes/AllRoutes";
import "./App.css";

function App() {
  const { data } = useContext(AppContext); // Use AppContext here

  console.log(data);

  return (
    <div className="App">
      <AllRoutes />
    </div>
  );
}

export default App;
