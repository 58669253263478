import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import "../CSS/sdk.css";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../context/AppContext";

function Home() {
  const [modalIsOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  const { setOptionData } = useContext(AppContext);

  useEffect(() => {
    const handleMessage = (e) => {
      if (e?.data?.options?.channelId === "WEB") {
        setOptionData(e.data.options);
        navigate("/next");
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [setOptionData, navigate]);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "20px",
      backgroundColor: "#6CF",
      height: "100%",
      padding: 0,
    },
    overlay: {
      overflowY: "hidden",
    },
  };

  return (
    <Modal isOpen={modalIsOpen} style={customStyles} contentLabel="Loading" ariaHideApp={false}>
      <div
        style={{
          backgroundColor: "#ffffff",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <img
          src={"https://epay-imagess.s3.ap-northeast-2.amazonaws.com/gifFile.GIF"}
          style={{ width: "360px", height: "200px" }}
          loading="lazy"
          alt="Loading animation"
        />
      </div>
    </Modal>
  );
}

export default Home;
