import axios from 'axios';


// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: "https://5afhuhtdfa.execute-api.ap-northeast-2.amazonaws.com/buy", // Adjust the base URL as needed
  // baseURL: 'http://v2apis.epayme.group/v1/auth', // Adjust the base URL as needed
  // baseURL: 'http://localhost:3000/v1/auth', // Adjust the base URL as needed
});


axiosInstance.interceptors.request.use(
  req => {
    if (typeof window !== 'undefined') {
        req.headers['x-api-key'] = '3pqeYRtlwJ47coQrb2Oid6dabDWvRNtZ3AckkJ69';
    }
    return req
  },
  err => {
    return Promise.reject(err)
  }
)

// Add a response interceptor
// axiosInstance.interceptors.response.use(
//   async (response) => {
// // console.log({response})
//     const urls = ["direct-sdk/pro-access-token",] // Define the URLs that should be excluded from decryption

//     if (response.data && !urls.includes(response.config.url)) {
//       try {
//     // console.log({responsecheck:response.data})

//         const decryptedData = await decryptData(response.data);

//         response.data = JSON.parse(decryptedData);
//       } catch (error) {
//         return Promise.reject(error);
//       }
//     }
//     // console.log("inter", response)
//     return response;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

export default axiosInstance;
